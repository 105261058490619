import React from "react"
import {Column, Columns, Container} from "bloomer";
import {GlobalBackgroundColors, GlobalStyle} from "../../styles/GlobalStyles";
import {ContItem, ContItemName, MyAniLink, GlobalStyleColumns} from "./styles";
import Item1 from '../../images/obras/item-01.jpg';
import Item2 from '../../images/obras/item-02.jpg';

const ObrasComp = ({data}) => {

  return(
    <Container isFluid className='full-height'>
      <div className='cont-page page-obras'>
        <GlobalStyle/>
        <GlobalStyleColumns/>
        <Columns isMultiline className="columns-responsive margin-no full-height">
          <Column className='padding-no' isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
            <ContItem src={Item1}>
              <MyAniLink
                paintDrip
                hex={GlobalBackgroundColors.bgContentLight}
                to={'/bordados'}
              >
                <ContItemName>
                  Pinturas
                </ContItemName>
              </MyAniLink>
            </ContItem>
          </Column>
          <Column className='padding-no' isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
            <ContItem src={Item2}>
              <MyAniLink
                paintDrip
                hex={GlobalBackgroundColors.bgContentLight}
                to={'/bordados'}
              >
                <ContItemName>
                  Grabados
                </ContItemName>
              </MyAniLink>
            </ContItem>
          </Column>
          <Column className='padding-no' isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
            <ContItem src={Item1}>
              <MyAniLink
                paintDrip
                hex={GlobalBackgroundColors.bgContentLight}
                to={'/bordados'}
              >
                <ContItemName>
                  Collages
                </ContItemName>
              </MyAniLink>
            </ContItem>
          </Column>
          <Column className='padding-no' isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
            <ContItem src={Item1}>
              <MyAniLink
                paintDrip
                hex={GlobalBackgroundColors.bgContentLight}
                to={'/bordados'}
              >
                <ContItemName>
                  Dibujos
                </ContItemName>
              </MyAniLink>
            </ContItem>
          </Column>
          <Column className='padding-no' isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
            <ContItem src={Item1}>
              <MyAniLink
                paintDrip
                hex={GlobalBackgroundColors.bgContentLight}
                to={'/bordados'}
              >
                <ContItemName>
                  Juguetes
                </ContItemName>
              </MyAniLink>
            </ContItem>
          </Column>
          <Column className='padding-no' isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
            <ContItem src={Item1}>
              <MyAniLink
                paintDrip
                hex={GlobalBackgroundColors.bgContentLight}
                to={'/bordados'}
              >
                <ContItemName>
                  Libros
                </ContItemName>
              </MyAniLink>
            </ContItem>
          </Column>

        </Columns>
      </div>
    </Container>
  )
}

export default ObrasComp
