import styled, {createGlobalStyle} from 'styled-components';
import {GlobalFonts} from "../../styles/fonts";
import {GlobalBackgroundColors, GlobalColors} from "../../styles/GlobalStyles";
import {Link} from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const GlobalStyleColumns = createGlobalStyle`
  
  @media screen and (min-width: 300px){
    .columns:not(.is-desktop) {
      display: flex !important;
    }
  }
`

export const ContItem = styled.div`
  width: 100%;
  height: 100%;
  min-height: 150px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`
export const LinkObras = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-family: ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorMenuSecondary};
  
  &:hover{
    background-color: rgba(237, 90, 88, .8);
  }
   
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 30px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 38px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 38px;    
  }
  @media(min-width: 1408px){
    font-size: 38px;
  }
`
export const ContItemName = styled.div`
  padding: 10px 25px;
  
  display: none;
  
  /*background-color: ${GlobalBackgroundColors.bgSecondary};
  border-radius: 10px;
  opacity: 0.8;*/
  
  opacity: 1;
`
export const MyAniLink = styled(AniLink)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-family: ${GlobalFonts.fontMedium};
  /*color: ${GlobalColors.colorMenuSecondary};*/
  color: ${GlobalColors.colorMenuPrimary};
  
  -webkit-transition: all 0.7s 0s ease;
  -moz-transition: all 0.7s 0s ease;
  -o-transition: all 0.7s 0s ease;
  transition: all 0.7s 0s ease;
  
  &:hover{
    background-color: rgba(237, 90, 88, .8);
  }
  
  &:hover div{
    display: block;
    color: ${GlobalColors.colorMenuPrimary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 32px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 32px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 34px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 36px;
  }
  @media(min-width: 1408px){
    font-size: 38px;
  }
`

