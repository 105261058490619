import * as React from "react"
import Layout from '../components/Layout'
import SEO from "../components/seo"
import ObrasComp from "../components/Obras";

const ObrasPage = ({data}) => {
  //const obras = data.allObrasJson

  return (
    <Layout
      themeColor={'light'}
      clsOverflow={true}
    >
      <SEO
        title="Obras"
        description="Las obras de Georgina Quintana van desde pinturas, dibujos, retratos, collages, grabados y juguetes."
        works='georgina quintana, pinturas, dibujos, retratos, collages, grabados, juguetes'
        bolImage='1'
        ogImage={`obras_f.jpg`}
        twitterImage={`obras_t.jpg`}
      />

      <ObrasComp/>

    </Layout>
  )
}

export default ObrasPage
